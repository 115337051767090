:root, [data-bs-theme=light] {
  --bs-primary: #483C32;
  --bs-primary-rgb: 72,60,50;
  --bs-primary-text-emphasis: #1D1814;
  --bs-primary-bg-subtle: #DAD8D6;
  --bs-primary-border-subtle: #B6B1AD;
  --bs-body-font-family: 'Nunito Sans', sans-serif;
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #483C32;
  --bs-btn-border-color: #483C32;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #3D332B;
  --bs-btn-hover-border-color: #3A3028;
  --bs-btn-focus-shadow-rgb: 228,226,224;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #3A3028;
  --bs-btn-active-border-color: #362D26;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #483C32;
  --bs-btn-disabled-border-color: #483C32;
}

.btn-outline-primary {
  --bs-btn-color: #483C32;
  --bs-btn-border-color: #483C32;
  --bs-btn-focus-shadow-rgb: 72,60,50;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #483C32;
  --bs-btn-hover-border-color: #483C32;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #483C32;
  --bs-btn-active-border-color: #483C32;
  --bs-btn-disabled-color: #483C32;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #483C32;
}

.react-datepicker__input-container {
  width: inherit;
}

.react-datepicker-wrapper {
  width: 100%;
}

.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}